import React from 'react';
import './Modal.css';


function Modal({ onClose }) {
    return (
        <div className="modal-overlay">
            <div className="modal">
                <h2>In construction</h2>
                <p>Sorry for the mess, I am working on it!</p>
                <img src={`${process.env.PUBLIC_URL}/images/Construction.png`} alt="Construction" width="250" />
                <br></br>
                <button onClick={onClose}>Close</button>
                
            </div>
        </div>
    );
}

export default Modal;
