import React, { useState } from 'react';
import './Navbar.css';

function Navbar() {
  const [isNavOpen, setNavOpen] = useState(false);

  const toggleNav = () => setNavOpen(!isNavOpen);

  return (
    <header>
      <nav>
        <button 
          className="nav-toggle" 
          onClick={toggleNav}
          aria-expanded={isNavOpen}
          aria-controls="nav-menu"
        >
          {isNavOpen ? '✕' : '☰'}
        </button>
        <ul id="nav-menu" className={isNavOpen ? "open" : ""}>
          <a href="#home" onClick={() => setNavOpen(false)}>Home</a>
          <a href="#about" onClick={() => setNavOpen(false)}>About Me</a>
          <a href="#education" onClick={() => setNavOpen(false)}>Education</a>
          <a href="#certifications" onClick={() => setNavOpen(false)}>Certifications</a>
          {/* <a href="#projects" onClick={() => setNavOpen(false)}>Projects</a> */}
          <a href="#contact" onClick={() => setNavOpen(false)}>Contact</a>
        </ul>
      </nav>
    </header>
  );
}

export default Navbar;
