import React from 'react';
import './Certifications.css';

const Certifications = () => {
  return (
    <section className="section certifications" id="certifications">
      <br></br>
      <h2>Certifications</h2>
      <div className="cert-container">
        <img src={`${process.env.PUBLIC_URL}/images/comptia_pentest.jpg`} alt="CompTIA Pentest+" width="20%" />
        <p>CompTIA Pentest+</p>
      </div>
      <div className="cert-container">
        <img src={`${process.env.PUBLIC_URL}/images/comptia_security.jpg`} alt="CompTIA Security+" width="20%" />
        <p>CompTIA Security+</p>
      </div>
      <div className="cert-container">
        <img src={`${process.env.PUBLIC_URL}/images/comptia_network.jpg`} alt="CompTIA Network+" width="20%" />
        <p>CompTIA Network+</p>
      </div>
      <div className="cert-container">
        <img src={`${process.env.PUBLIC_URL}/images/comptia_a.jpg`} alt="CompTIA A+" width="20%" />
        <p>CompTIA A+</p>
      </div>
      <div className="cert-container">
        <img src={`${process.env.PUBLIC_URL}/images/microsoft_office_specialist.jpg`} alt="Microsoft Office Specialist" width="20%" />
        <p>Microsoft Office Specialist</p>
      </div>
      <div className="cert-container">
        <img src={`${process.env.PUBLIC_URL}/images/Testout_NetworkPro.jpg`} alt="TestOut Network Pro" width="20%" />
        <p>TestOut Network Pro</p>
      </div>
      <div className="cert-container">
        <img src={`${process.env.PUBLIC_URL}/images/Testout_PCPro.jpg`} alt="TestOut PC Pro" width="200%" />
        <p>TestOut PC Pro</p>
      </div>
      <h3>In progress...</h3>
      <div className="cert-container">
        <img src={`${process.env.PUBLIC_URL}/images/CEH.png`} alt="Certified Ethical Hacker" width="250" />
        <p>Certified Ethical Hacker</p>
      </div>
    </section>
  );
};

export default Certifications;
