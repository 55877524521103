import React from 'react';
import './About.css';

function About() {
  return (
    <section className="section about" id="about">
      <br></br>
      <h2>About Me</h2>
      <div className="about-content">
        <div className="about-image">
          <img src={`${process.env.PUBLIC_URL}/images/MasonCoco.jpeg`} alt="Profile Picture" />
        </div>
        <div className="about-text">
          <p>Hi there 👋,</p>
            <p>I grew up in Lakewood, Colorado, surrounded by nature, which sparked my love for the outdoors. When I'm not diving deep into cybersecurity, you can find me out skiing, hiking, or wakeboarding—anything that lets me connect with nature and stay active!</p>

          <p>I'm passionate about cybersecurity, and when I'm not outdoors, I enjoy working on tech projects like rooting phones, developing AI-integrated applications, or refining this personal website. I love blending creativity with technical challenges and am always excited about new collaborations. Feel free to reach out—I’m always up for a chat!</p>
        </div>
      </div>
    </section>
  );
}

export default About;
